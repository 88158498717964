[class^="devicon-"],
[class*=" devicon-"] {
  font-size: 78px;
}

.cardHeading {
  color: #000;
  padding-top: 10px;
}

.avatar-hover-effect img {
  transition: transform 0.3s ease-in-out;
}

.avatar-hover-effect:hover img {
  transform: scale(1.1); /* Zoom in the image by scaling */
}
