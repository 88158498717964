.otwCard:hover .otwCard-box {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(
    -2px
  ) !important; /* Move the element up by 7px on hover */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Contact me button hover on card hover */
.otwCard:hover svg .bg {
  stroke: #ff4081; /* Change stroke color */
}

.otwCard:hover svg .borderEffect {
  stroke-dashoffset: -600; /* Trigger the border dash animation */
}

.otwCard:hover svg {
  --main-color: #ff4081 !important;
}
