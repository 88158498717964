.ResumeHomeBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResumeHomeBlock.ScreenBlock h1 {
  margin: 0;
  font-weight: 450;
  padding-bottom: 8px;
  font-size: 90px;
}

.ResumeHomeBlock.ScreenBlock h2 {
  margin: 0;
  color: #fff;
}

.ResumeHomeBlock-squares {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  overflow: hidden;
  text-align: center;
  background: #000;
}

.ResumeHomeBlock-squares > div {
  opacity: 0.5;
}

.ResumeHomeBlock-squares > div > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ResumeHomeBlock-squares .SVGInline {
  width: 40%;
}

.ResumeHomeBlock-squares .SVGInline svg path,
.ResumeHomeBlock-squares .SVGInline svg g {
  fill: #fff !important;
}

/* ResumeHomeBlock.css */

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ResumeHomeBlock-headline-container {
  color: #fff;
  text-align: center;
  padding: 35px 50px 35px;
  max-width: 1300px;
  border: 1px solid #fff;
  z-index: 1;
  background: rgba(0, 0, 0, 0.72);
  animation: slideIn 1s ease-in-out forwards; /* Apply animation */
  opacity: 0; /* Start with opacity 0 for the animation to work */
}

.ResumeHomeBlock-sns-container {
  z-index: 1;
}

@media only screen and (max-width: 800px) {
  .ResumeHomeBlock h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .ResumeHomeBlock h1 {
    font-size: 42px !important;
    line-height: 48px;
  }
}

@media only screen and (max-width: 480px) {
  .ResumeHomeBlock-squares {
    height: calc(100vh - 88px);
  }
  .speakerButton {
    margin-bottom: 0px !important;
  }
  .ResumeHomeBlock-headline-container {
    padding: 20px 30px 20px !important;
  }
}

@media only screen and (max-width: 360px) {
  .ResumeHomeBlock h1 {
    font-size: 26px;
  }

  .ResumeHomeBlock h2 {
    font-size: 15px;
  }
  .speakerButton {
    margin-bottom: 0px !important;
  }
}

.speakerButton {
  margin-bottom: 15px;
  color: grey !important;
}

.speakerButton:hover {
  background-color: rgba(0, 0, 0, 0.35) !important;
  color: #ff4081 !important;
  /* color: #fe481d !important; */
}

/*Floating arrow*/
.floating-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: smooth-up-down 2s infinite;
  transition: transform 0.3s ease, color 0.3s ease;
  color: #212529;
}

.floating-arrow:hover {
  transform: translateX(-50%) scale(1.2); /* Enlarges the arrow slightly */
  color: #ff4081 !important; /* Change color on hover */
}

@keyframes smooth-up-down {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 10px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
