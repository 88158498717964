.vertical-timeline-element--work:hover .vertical-timeline-element-content,
.vertical-timeline-element--education:hover .vertical-timeline-element-content {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px); /* Move the element up by 7px on hover */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* change button background color on hover*/
/*
 .vertical-timeline-element--work:hover
  .vertical-timeline-element-content
  button,
.vertical-timeline-element--education:hover
  .vertical-timeline-element-content
  button {
  background-color: #1976d2;
  color: #fff; 
} 
*/

/* Hover effect for timeline middle icon */
/* .vertical-timeline-element--work:hover .vertical-timeline-element-icon,
.vertical-timeline-element--education:hover .vertical-timeline-element-icon {
  background-color: #ff4081 !important;
} */

.vertical-timeline-element--education .vertical-timeline-element-content {
  /* border: 5px solid brown; old */
  border-top: 4px solid black;
}

.vertical-timeline-element--work .vertical-timeline-element-content {
  border-top: 4px solid #2196f3;
}

.vertical-timeline-element {
  margin: 0px;
  padding: 2em 0;
}

/* Original styles for text and other elements */
.timeLineDate {
  color: #333;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px; /* Removed the quotes from font-size */
}

.vertical-timeline-element:last-child {
  padding-bottom: 0px;
}

#location {
  font-weight: 100;
  font-size: 15px;
  color: grayText;
  font-style: italic;
}

#company {
  font-weight: 100;
  font-size: 1.24rem;
}

/* Trigger hover effect for SvgButton's SVG parts (polygons) */
.vertical-timeline-element--work:hover
  .vertical-timeline-element-content
  svg
  .bg,
.vertical-timeline-element--education:hover
  .vertical-timeline-element-content
  svg
  .bg {
  stroke: #1976d2; /* Change stroke color */
}

.vertical-timeline-element--work:hover
  .vertical-timeline-element-content
  svg
  .borderEffect,
.vertical-timeline-element--education:hover
  .vertical-timeline-element-content
  svg
  .borderEffect {
  stroke-dashoffset: -600; /* Trigger the border dash animation */
}
