.ProjectCards-projects {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding: 0px 50px 50px 50px; */
}

.ProjectCards-project-container {
  display: inline-block;
  perspective: 900px;
  width: 400px;
  height: 400px;
  margin: 10px;
}

.ProjectCards-project {
  transition: all 0.6s ease;
  transform-style: preserve-3d;
}

.ProjectCards-project-front,
.ProjectCards-project-back {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}

.ProjectCards-project-container:hover .ProjectCards-project {
  transform: rotateY(180deg);
}

.ProjectCards-project-back {
  transform: rotateY(180deg);
}

.ProjectCards-project > div > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 16px 0 16px !important;
  /* height: 100%; */
}

.ProjectCards-project-back-name {
  font-weight: 500;
  font-size: 22px;
}

.ProjectCards-project-back-description {
}

.ProjectCards-project img {
  align-self: center;
  max-width: 100%;
}

@media only screen and (max-width: 800px) {
  .ProjectCards-projects {
    padding: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .ProjectCards-projects {
    padding: 20px;
  }
}

.lazyload-wrapper {
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.css-fz3nk2-MuiButton-startIcon > *:nth-of-type(1) {
  font-size: 20px !important;
}
.css-8dhhus-MuiButton-root {
  font-size: 0.916rem !important;
}
