/* blogs.css */
@media (max-width: 700px) {
  .MuiTypography-h5 {
    font-size: 18px !important;
  }
  .MuiTypography-subtitle2 {
    font-size: 10px !important;
  }
  .MuiTypography-body1 {
    font-size: 14px !important;
  }
}
